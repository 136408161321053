<template>
  <div class="apply">
    <div class="apply__header">
      <div class="apply__header_btn">
        <el-button type="text" icon="el-icon-arrow-left" @click="to">返回众测</el-button>
      </div>

      <div class="apply__header_title">众测申请</div>
    </div>
    <div class="apply__center">
      <div class="apply__center_img">
        <img :src="infoData.apparatusFileId" alt="" />
      </div>
      <div class="apply__center_msg">
        <div class="apply__center_msg-text">{{ infoData.apparatusName }}</div>
        <div class="apply__center_msg-text">名额：{{ infoData.quantify }}</div>
      </div>
    </div>
    <div class="apply__header">申请理由</div>
    <div class="apply__textarea">
      <el-input
        type="textarea"
        :rows="8"
        placeholder="您可以阐述自己的优势，对产品的理解，以及您的评测理念，都将提高申请成功率。"
        v-model="textarea2"
      >
      </el-input>
    </div>
    <div class="apply_bottom">
      <div class="close">
        <el-button type="text"> 取消</el-button>
      </div>
      <div>
        <el-button type="primary" round @click="commit()">提交申请</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../api/api";
import check from "../api/check";
export default {
  data() {
    return {
      textarea2: "",
      infoData: [],
    };
  },
  created() {
    this.assessInfo();
  },
  methods: {
    assessInfo() {
      let data = {
        assessId: this.$route.params.id,
      };
      check.assessInfo(data).then((res) => {
        this.infoData = res.data.data;
      });
    },
    commit() {
      let data = {
        assessId: this.$route.params.id,
        plan: this.textarea2,
      };
      check.assessApply(data).then((res) => {
        this.$message({message:res.data.msg, type: "warning" ,duration:1200 });
      });
    },
    to(){
      this.$router.go(-1);
    }
  },
};
</script>
<style lang="scss">
.apply__header {
  .el-button {
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
}
.apply_bottom {
  .close {
    .el-button {
      width: 213px;
      height: 64px;
      background: #fbfbfb;
      box-shadow: 0px 0px 0px 0px;
      border-radius: 65px 65px 65px 65px;
      font-size: 21px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
  .el-button {
    width: 213px;
    height: 64px;
    background: #0394ff;
    box-shadow: 0px 5px 24px 1px
      rgba(2.9999998211860657, 147.9999303817749, 255, 0.5099999904632568);
    border-radius: 65px 65px 65px 65px;
    font-size: 21px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
}
.apply__textarea {
  margin-top:20px ;
  .el-textarea {
    .el-textarea__inner {
      background: #fbfbfb;
      font-size: 21px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #b6c3cd;
    }
  }
}
</style>
<style lang="scss" scoped>
.apply {
  min-height: 663px;
  .apply__header {
    font-size: 21px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    padding: 12px 0;
    position: relative;

    .apply__header_btn {
      left: -97px;
      bottom: 0;
      position: absolute;
    }
  }
  .apply__center {
    display: flex;
    margin-top: 27px;
    margin-bottom: 37px;
    .apply__center_msg {
      margin-left: 40px;
      font-size: 21px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #999999;
      .apply__center_msg-text {
        padding: 12px 0;
      }
    }
  }
  .apply_bottom {
    padding: 48px 0;
    text-align: right;
    div {
      display: inline-block;
      &:nth-child(1) {
        margin-right: 35px;
      }
    }
  }
}
</style>